<template>
  <div>
    <div class="grid grid-cols-11 gap-3 items-center">
      <div class="relative col-span-5">
        <CarvisTextField
          :id="`${attribute}-min`"
          block
          class="!shadow-none !ring-0 border-b rounded-none !px-0.5 !py-0 !h-6 -mt-0.5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          hide-error-row
          :model-value="lowerValue"
          type="number"
          @change="onChange('from', $event)"
        />
        <span
          v-if="unit"
          class="absolute top-0 right-0 text-xs text-gray-400 mt-0.5"
        >
          {{ unit }}
        </span>
      </div>
      <span class="text-center">-</span>
      <div class="relative col-span-5">
        <CarvisTextField
          :id="`${attribute}-max`"
          block
          class="!shadow-none !ring-0 border-b rounded-none !px-0.5 !py-0 !h-6 -mt-0.5 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          hide-error-row
          :model-value="upperValue"
          type="number"
          @change="onChange('to', $event)"
        />
        <span
          v-if="unit"
          class="absolute top-0 right-0 text-xs text-gray-400 mt-0.5"
        >
          {{ unit }}
        </span>
      </div>
    </div>
    <Slider
      class="relative flex items-center ml-2 mr-2.5 mt-3 mb-2.5"
      :max="max"
      :min="min"
      :model-value="[lowerValue, upperValue]"
      :pt="{
        startHandler: 'bg-white border border-gray-400 h-5 w-5 rounded-full shadow-sm outline-none hover:border-blue-light hover:border-2 cursor-grab -ml-2.5',
        endHandler: 'bg-white border border-gray-400 h-5 w-5 rounded-full shadow-sm outline-none hover:border-blue-light hover:border-2 cursor-grab -ml-2.5',
        range: 'bg-gray-400 h-1 cursor-pointer',
        root: 'bg-gray-200 h-1 rounded-full'
      }"
      range
      :step="steps"
      @change="onSliderChange"
      @slideend="onSlideEnd"
    />
  </div>
</template>

<script setup lang="ts">
import Slider from 'primevue/slider'
import { computed, ref, toRaw, watch } from 'vue'

const props = defineProps<{
  modelValue: number[]
  min: number
  max: number
  unit?: string
  steps?: number
  attribute: string
}>()

const emit = defineEmits(['update:modelValue'])

const lowerValue = ref<number>(props.modelValue[0] ?? props.min)
const upperValue = ref<number>(props.modelValue[1] ?? props.max)

watch(() => props.modelValue, (newValue) => {
  if (newValue[0] !== undefined) {
    lowerValue.value = newValue[0]
  } else {
    lowerValue.value = props.min
  }
  if (newValue[1] !== undefined) {
    upperValue.value = newValue[1]
  } else {
    upperValue.value = props.max
  }
})

const sanitizeValue = (value: string): number | null => {
  const v = parseInt(value)
  if (isNaN(v)) return null
  return v
}

const onChange = (type: 'from' | 'to', value: string): void => {
  const arr: number[] = []

  if (type === 'from') {
    arr.push(sanitizeValue(value) ?? props.min)
    arr.push(toRaw(upperValue.value))
  } else {
    arr.push(toRaw(lowerValue.value))
    arr.push(sanitizeValue(value) ?? props.max)
  }
  arr.sort((a, b) => a - b)
  emit('update:modelValue', arr)
}

const onSliderChange = (values: number[]): void => {
  values.sort((a, b) => a - b)
  lowerValue.value = values[0]
  upperValue.value = values[1]
}

const onSlideEnd = ({ value }: { value: number[]}): void => {
  value.sort((a, b) => a - b)
  emit('update:modelValue', value)
}

const steps = computed(() => {
  if (props.steps === undefined) return undefined
  if (((props.max - props.min) / props.steps) < 10) {
    return (props.max - props.min) / 10
  }
  return props.steps
})
</script>
